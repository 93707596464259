/*
 * hide-navbar.js - Hides the navbar when a window is opened
 */
'use strict';

jQuery(document).ready(function() {
    Sao.Tab.create_procentral_theme = Sao.Tab.create;
    Sao.Tab.create = function(attributes) {
        Sao.Tab.create_procentral_theme(attributes);
        jQuery('#main_navbar').collapse('hide');
    };
});
