/*
 * filter-close.js - Adds a button to close the filter box
 */
'use strict';

jQuery(document).ready(function() {
    Sao.ScreenContainer.prototype.search_box_procentral_theme = Sao.ScreenContainer.prototype.search_box;
    Sao.ScreenContainer.prototype.search_box = function() {
        var search_box = this.search_box_procentral_theme();
        var clear = function(evt) {
            evt.preventDefault();
            for (var i = 0; i < this.search_form.fields.length; i++) {
                var entry = this.search_form.fields[i][1];
                if (entry instanceof Sao.ScreenContainer.Selection) {
                    entry.set_value([]);
                } else if (entry instanceof Sao.ScreenContainer.Between) {
                    entry.set_value(null, null);
                } else {
                    entry.val('');
                }
            }
        }.bind(this);
        var cancel = function(evt) {
            evt.preventDefault();
            this.search_modal.modal('hide');
            this.set_text(this.last_search_text);
        }.bind(this);

        var footer = this.search_modal.find('.modal-footer');
        if (footer[0].children.length != 3) {
            jQuery('<a/>', {
                'href': '#',
                'class': 'btn',
                'title': Sao.i18n.gettext("Clear"),
            }).text(Sao.i18n.gettext('Clear'))
            .click(clear).prependTo(footer);
            jQuery('<a/>', {
                'href': '#',
                'class': 'btn',
                'title': Sao.i18n.gettext("Cancel"),
            }).text(Sao.i18n.gettext('Cancel'))
            .click(cancel).prependTo(footer);
        }
        return search_box;
    };
});
