/*
 * login-dialog.js - Patches sao to allow styling when the login dialog is open
 */
'use strict';

jQuery(document).ready(function() {
    Sao.Session.get_credentials_standard = Sao.Session.get_credentials;
    Sao.Session.get_credentials = function() {
        jQuery('body').addClass('login');
        var promise = Sao.Session.get_credentials_standard();
        promise.always(function () {
            jQuery('body').removeClass('login');
        });
        return promise;
    };
});
