/*
 * time-optional-seconds.js - Only display seconds if non zero
 */
'use strict';

jQuery(document).ready(function() {
    Sao.View.Form.DateTime.prototype.display = function() {
        Sao.View.Form.DateTime._super.display.call(this);
        var value = this.date.val();
        if (this.get_format().endsWith(':%S') && value.endsWith(':00')) {
            this.date.val(value.slice(0, -3));
        }
    };
    Sao.View.Form.Time.prototype.display = function() {
        Sao.View.Form.Time._super.display.call(this);
        var value = this.date.val();
        if (this.get_format().endsWith(':%S') && value.endsWith(':00')) {
            this.date.val(value.slice(0, -3));
        }
    };
    Sao.View.Tree.TimeColumn.prototype.update_text = function(cell, record) {
        var value = this.field.get_client(record);
        var format = this.field.time_format(record);
        var text = Sao.common.format_time(format, value);
        if (format.endsWith(':%S') && text.endsWith(':00')) {
            text = text.slice(0, -3);
        }
        cell.text(text).attr('title', text);
    };
});
