/*
 * menu-indent.js - Re-applies the menu indent more stylably
 */
'use strict';

jQuery(document).ready(function() {
    Sao.View.Tree.Row.prototype.redraw_procentral_theme = Sao.View.Tree.Row.prototype.redraw;
    Sao.View.Tree.Row.prototype.redraw = function(selected, expanded) {
        this.redraw_procentral_theme(selected, expanded);
        if (this.expander) {
            var margin = (
                this.expander.children()[0].style.marginLeft ||
                this.expander.children()[0].style.marginRight);
            if (margin) {
                this.el[0].classList.add(
                    'tree-level-' + margin.replace('em', ''));
            }
        }
    };
});
