/*
 * calendar-format.js - Updates calendar buttons, formatting and style
 */
'use strict';

jQuery(document).ready(function() {
    Sao.View.CalendarListXMLViewParser.prototype._parse_calendar_procentral_theme = Sao.View.CalendarListXMLViewParser.prototype._parse_calendar;
    Sao.View.CalendarListXMLViewParser.prototype._parse_calendar = function(node, attributes) {
        this._parse_calendar_procentral_theme(node, attributes);

        var options = [
            'defaultView',
            'header',
            'timeFormat',
            'events',
            'contentHeight',
            'locale',
            'isRTL',
            'themeSystem',
            'bootstrapGlyphicons',
            'buttonTextOverride',
            'eventRender',
            'eventResize',
            'eventDrop',
            'eventClick',
            'dayClick'
        ];
        var args = {};
        for (var i in options) {
            args[options[i]] = this.view.el.fullCalendar('option', options[i]);
        }

        args.header = {
            left: 'today',
            center: 'prev title next',
            right: args.header.right.replace(/,/g, ' ')
        };
        args.views = {
            month: {
                timeFormat: ' ',
                titleFormat: 'MMM YYYY'
            },
            week: {
                titleFormat: 'DD MMM',
                columnHeaderFormat: 'ddd DD'
            },
            day: {
                titleFormat: 'DD MMM YYYY',
                columnHeaderFormat: 'ddd'
            },
        };
        // TODO: get scrollTime to work
        args.minTime = '07:00:00';

        this.view.el.fullCalendar('destroy');
        this.view.el.fullCalendar(args);
    };
});

