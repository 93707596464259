/*
 * error-dialog.js - Replaces the standard error dialog with a simpler one
 */
'use strict';

jQuery(document).ready(function() {
    Sao.common.ErrorDialog = Sao.class_(Sao.common.UniqueDialog, {
        class_: 'error-dialog',
        build_dialog: function(title, details, prm) {
            var dialog = Sao.common.ConcurrencyDialog._super.build_dialog.call(
                this);
            var error_time = new Date();
            var RFC4648_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
            var issue_number = (
                window.location.hostname.slice(0,2) +
                window.location.hostname.slice(-1) +
                '-' +
                RFC4648_CHARS[error_time.getUTCDate()] +
                RFC4648_CHARS[error_time.getUTCHours()] +
                ('0' + error_time.getUTCMinutes()).slice(-2)
                ).toUpperCase();
            dialog.modal.find('.modal-dialog').addClass('application-error');
            dialog.add_title(Sao.i18n.gettext('Application Error'));
            dialog.body.append(
                Sao.common.ICONFACTORY.get_icon_img('tryton-error', {
                'aria-hidden': true,
            })).append(jQuery('<p/>', {
                'class': 'h4'
            }).text(
                "Something went wrong")
            ).append(jQuery('<p/>').text(
                "Please quote reference number " +
                issue_number + " " +
                "for enquiries about this error.")
            ).append(jQuery('<p>', {
                'style': 'display: none',
            }).text(details));
            jQuery('<button/>', {
                'class': 'btn btn-primary',
                'type': 'button',
                'title': Sao.i18n.gettext("Close"),
            }).text(Sao.i18n.gettext('Close')).click(function() {
                this.close(dialog);
                prm.resolve();
            }.bind(this)).appendTo(dialog.footer);
            if (typeof Sentry !== 'undefined' && details) {
                Sentry.captureException(new Error(
                    issue_number + ':\n...' + details.slice(-236)));
            }
            return dialog;
        }
    });
    if (!window.location.href.startsWith('http://localhost')) {
        Sao.common.error = new Sao.common.ErrorDialog();
    }
});
