/*
 * hide-database.js - Hides the database field on the login screen when possible
 */
'use strict';

jQuery(document).ready(function() {
    Sao.Session.login_dialog_hide_database = Sao.Session.login_dialog;
    Sao.Session.login_dialog = function() {
        var dialog = Sao.Session.login_dialog_hide_database();

        var observer = new MutationObserver(function() {
            var input = dialog.database_input[0];
            if (input.readOnly) {
                input.parentElement.style.display = 'none';
            }
        });
        observer.observe(dialog.database_input[0], {attributes: true});
        return dialog;
    };
});
