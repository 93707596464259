/*
 * tree-sum.js - hide "0 /" from the start of sums
 */
/* jshint esversion: 6 */
'use strict';

jQuery(document).ready(function() {
    Sao.View.Tree.prototype.update_sum_procentral_theme = Sao.View.Tree.prototype.update_sum;
    Sao.View.Tree.prototype.update_sum = function() {
        this.update_sum_procentral_theme();
        for (const [column, sum_widget] of this.sum_widgets) {
            var sum_value = sum_widget[1];
            var selected_sum = sum_value.text().split(' / ');
            if (selected_sum[0] == 0) {
                sum_value.text(selected_sum[1]);
            }
        }
    };
});
