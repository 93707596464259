/*
 * toolbar.js - Changes to improve the layout and look of the toolbar
 */
'use strict';

jQuery(document).ready(function() {
    Sao.Tab.prototype.create_toolbar_standard = Sao.Tab.prototype.create_toolbar;
    Sao.Tab.prototype.create_toolbar = function() {
        var toolbar = this.create_toolbar_standard();
        toolbar.find('.navbar-inverse').addClass('navbar').removeClass('navbar-inverse');
        toolbar.find('.btn-toolbar').append(
            toolbar.find('button[title="Close"]'));
        return toolbar;
    };
});
